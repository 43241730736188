<template>
  <div class="home">

    <svg @click="event.log(['click','logo'])"
      xmlns="http://www.w3.org/2000/svg"
      width="5.55556in" height="5.83333in"
      viewBox="0 0 400 420">
      <path id="Selection"
        fill="green" stroke="black" stroke-width="1"
        d="M 46.00,43.00
        C 46.00,43.00 46.00,68.00 46.00,68.00
        46.02,83.22 49.65,109.67 52.42,125.00
        63.83,188.10 91.06,244.40 132.73,293.00
        146.76,309.37 163.06,323.93 180.00,337.21
        183.56,340.00 194.96,349.45 199.00,349.45
        203.04,349.45 214.44,340.00 218.00,337.21
        234.94,323.93 251.24,309.37 265.27,293.00
        306.94,244.40 334.17,188.10 345.58,125.00
        348.35,109.67 351.98,83.22 352.00,68.00
        352.00,68.00 352.00,43.00 352.00,43.00
        352.00,43.00 350.00,43.00 350.00,43.00
        346.87,66.72 340.40,99.59 326.08,119.00
        319.12,128.43 307.58,138.41 295.00,137.48
        279.98,136.38 271.61,128.68 259.00,122.37
        245.01,115.37 224.70,109.19 209.00,109.00
        186.36,108.74 171.55,108.90 150.00,117.60
        150.00,117.60 138.00,122.89 138.00,122.89
        126.10,129.16 117.09,136.45 103.00,137.48
        90.42,138.41 78.88,128.43 71.92,119.00
        57.60,99.59 51.13,66.72 48.00,43.00
        48.00,43.00 46.00,43.00 46.00,43.00 Z" />
    </svg>

    <welcome msg="" />

  </div>
</template>

<script>
import welcome from "@/components/welcome.vue"

import { eventService } from "@/service/event"

export default {

  data() {
    return {
      event: eventService,
      debug: 1
    }
  },

  components: {
    welcome
  },

  mounted() {
    this.event.log(['load','home'])
  },

  methods: {}
}
</script>

<style lang='scss'>
  .home {
    svg {
      fill: #6494B3;
      width: 16rem;
      height: 18rem;
      height: 15rem;
    }
  }
</style>




