import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
//import { auth } from '@/service/firebase'

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/dice",
    name: "dice",
    meta: { title: 'Warfish Dice Simulator' },
    component: () =>
      //import(/* webpackChunkName: "dice" */ "../dice")
      import('../dice')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
