import { createApp } from "vue"

import App from "./App.vue"
import router from "./router"
import store from "./store"

import { auth } from '@/service/firebase'
auth.onAuthStateChanged(() => {
  console.log('do we need to reboot the app?')
})

createApp(App)
.use(store)
.use(router)
.mount("#app")
