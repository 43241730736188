//import * as firebase from 'firebase/app'
import { firebase } from '@firebase/app'

import '@firebase/auth'
//import 'firebase/auth'
import '@firebase/firestore'

// firebase init - add your own config here
const config = {
  apiKey: "AIzaSyB8vXb3h08Jlk0MjNZwWFeSNKdBZ8AaZR0",
  authDomain: "catomatic-d5b4c.firebaseapp.com",
  projectId: "catomatic-d5b4c",
  storageBucket: "catomatic-d5b4c.appspot.com",
  messagingSenderId: "1063037200656",
  appId: "1:1063037200656:web:5c7c7117f5c027a833dd95",
  measurementId: "G-CYBRJZ01D8"
}
firebase.initializeApp(config)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const Users = db.collection('user')
const Events = db.collection('event')
const Faqs = db.collection('faq')

// testing by jj 
//const t2 = firebase.firestore.FieldValue.serverTimestamp()
//console.log( t2 )

// export utils/refs
export {
  db,
  auth,
  Users,
  Events,
  Faqs
}