import { Events } from "@/service/firebase"

const nom = '@service/event'
const debug = 1

/* ---------------------------------------------
 * log an event
 *  tags: array of strings
 *  countInc: how many to increment the counter
 */
const log = async function(tags, countInc=1) {

  if (debug) console.debug('%s.log(%s)', nom, tags)

  //const tags = ['click','logo']
  if (!Array.isArray(tags) || tags.length<1) return
  let updated = false

  try {
    const events = await Events.where('tags','array-contains',tags[0]).get()
    events.forEach(event => {
      if (debug) {
        console.log(event.id, '=>', event.data().tags)
        //console.log('compare tags: [%s] vs [%s]', event.data().tags, tags)
      }

      let matching = tags.every(tag => {

        if (debug) console.debug('is %s in %s? %d', tag, event.data().tags, event.data().tags.includes(tag))

        return event.data().tags.includes(tag)
      })

      if (debug) console.debug('matching? %d', matching)

      if (matching) {
        if (debug) console.log('tag match!')
        event.ref.update({
          count: event.data().count+countInc,
        })
        updated = true
      }
    })

    if (!updated) {// we have to create the event
      await Events.add({
        tags: tags,
        count: countInc
      })
    }

  } catch (derp) {
    console.log('eror saving event to db')
    console.log(derp)
  }

}

/* --+--+--+--+--+--+--+--+--+--+--+--+--+--+-- */

export const eventService = {
  log
}

/**/